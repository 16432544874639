.page-container {
  animation: page-container 1s forwards;
}

@keyframes page-container {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
